var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"z-10"},[_c('div',{staticClass:"flex items-center lg:text-base"},[_c('div',{staticClass:"mr-auto text-left"},[_c('AppHeaderNavPopoverGroup',{attrs:{"group-class":"xl:space-x-6 2xl:space-x-8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var onShowPopover = ref.onShowPopover;
return [(_vm.root)?_c('AppHeaderNavPopover',{staticClass:"global-nav__item hover:bg-pfm-grey-300 font-light min-w-0 last:rounded-br-2xl",attrs:{"placement":"full","type":"mega"},on:{"show":onShowPopover},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('AppHeaderNavItem',{attrs:{"label":_vm.root.name,"active":active}})]}},{key:"default",fn:function(){return [_c('AppCatalog',{staticClass:"min-h-30vh h-80vh mx-auto max-w-pfm-wrapper",attrs:{"categories":_vm.categories}})]},proxy:true}],null,true)}):_vm._e(),_vm._v(" "),(_vm.mainMenu.length)?_vm._l((_vm.mainMenu),function(item,index){return _c('AppHeaderNavPopover',{key:("mainMenuLeft-" + index),staticClass:"global-nav__item hover:bg-pfm-grey-300 min-w-0 last:rounded-br-2xl relative w-max",attrs:{"placement":"left"},on:{"show":onShowPopover},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
var active = ref.active;
return [_c('AppHeaderNavItem',_vm._b({attrs:{"label":item.name,"active":active,"icon-visible":!!(item.children && item.children.length)}},'AppHeaderNavItem',
                    item.alternateUrl
                      ? {
                          href: item.alternateUrl,
                        }
                      : {
                          to: _vm.localePath({
                            name: 'all',
                            params: { pathMatch: item.url },
                          }),
                        }
                  ,false))]}},(item.children && item.children.length)?{key:"default",fn:function(){return [_c('ul',{staticClass:"w-80 bg-white overflow-y-auto scrollbar-invisible max-h-70vh rounded-2xl shadow"},_vm._l((item.children),function(nestedItem,nestedIndex){return _c('li',{key:("subMenu-" + nestedIndex),staticClass:"bg-white first:rounded-t-2xl last:rounded-b-2xl hover:bg-pfm-grey-300"},[_c('BaseLink',{staticClass:"flex items-center py-3 px-3",attrs:{"path":nestedItem.alternateUrl ||
                        _vm.localePath({
                          name: 'all',
                          params: { pathMatch: nestedItem.url },
                        }),"label":nestedItem.name}})],1)}),0)]},proxy:true}:null],null,true)})}):_vm._e()]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }