var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-pfm-grey-300 px-5 py-1"},[_c('ul',{staticClass:"mx-auto max-w-pfm-wrapper flex items-center justify-center min-h-7 text-bold font-bold text-pfm-primary-darkest"},_vm._l((_vm.mainUsps),function(item){return _c('li',{key:item.name,staticClass:"w-1/4 pl-2 pr-8 flex items-center"},[_c('BaseIcon',{staticClass:"text-pfm-primary-darkest w-4.5 h-4.5 mr-2",attrs:{"name":"pfm-check-round"}}),_vm._v(" "),_c(item.alternateUrl ? 'a' : 'NuxtLink',_vm._b({tag:"component",staticClass:"hover:underline"},'component',
          item.alternateUrl
            ? {
                href: item.alternateUrl,
              }
            : {
                to: _vm.localePath(
                  item.url
                    ? {
                        name: 'all',
                        params: { pathMatch: item.url },
                      }
                    : {
                        name: 'index',
                      }
                ),
              }
        ,false),[_vm._v("\n        "+_vm._s(item.title)+"\n      ")])],1)}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }